import { reportBug } from 'utils';
import { Bot, CreateBotDetails, BotCreation, FetchBotsParams, FetchBotsResponse } from './types';
import apiCall from 'api';

export const fetchBots = async ({ authorized = false, ...params }: FetchBotsParams & { authorized?: boolean }) => {
  try {
    const request = await apiCall(authorized);
    const { data } = await request<FetchBotsResponse>({ url: `bots`, method: 'GET', params });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.detail?.msg, error });
    return Promise.reject(error);
  }
};

export const fetchBot = async (id: string | number | null) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `bots/${id}`, method: 'GET' });

    return data.data as Bot;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const fetchCreations = async (): Promise<BotCreation[]> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `bots/creations`, method: 'GET' });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return [];
  }
};

export const getCreation = async (id: string): Promise<BotCreation> => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `bots/creations/${id}`,
      method: 'GET'
    });
    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    throw error;
  }
};

export const generateBot = async (botAttributes: Record<string, string>): Promise<BotCreation> => {
  try {
    const request = await apiCall();
    const [{ data }] = await Promise.all([
      request({ url: 'bots/creations/new', method: 'POST', data: { ...botAttributes } }),
      new Promise(resolve => setTimeout(resolve, 5000)) // 5-second delay
    ]);
    return data.data;
  } catch (error: any) {
    const msg = error?.data?.message || error?.data?.detail?.[0]?.msg || 'Failed to generate Crush';
    reportBug({ msg, error, prodToast: true });
    throw error;
  }
};

export const regenerateBot = async (id: string): Promise<BotCreation> => {
  try {
    const request = await apiCall();
    const [{ data }] = await Promise.all([
      request({ url: `bots/creations/${id}/regenerate`, method: 'POST' }),
      new Promise(resolve => setTimeout(resolve, 5000)) // 5-second delay
    ]);
    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    throw error;
  }
};

export const createFinalizeDraft = async (draftData: { details: CreateBotDetails; id: string; draft_id: number }) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `bots/creations/${draftData.id}/draft/${draftData.draft_id}/finalize`,
      method: 'POST',
      data: { ...draftData.details }
    });
    return data.data;
  } catch (error: any) {
    const msg = error?.data?.message || error?.data?.detail?.[0]?.msg || 'Failed to finalize Crush';
    reportBug({ msg, error, prodToast: true });
    throw error;
  }
};

export const fetchCreationWith3Drafts = async (botAttributes: Record<string, string>) => {
  try {
    let result: BotCreation = await generateBot(botAttributes);

    while (result?.drafts?.length < 3) {
      result = await regenerateBot(result.id.toString());
    }

    return result;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const deleteCreation = async (id: number) => {
  try {
    const request = await apiCall();
    await request({ url: `bots/creations/${id}`, method: 'DELETE' });
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    throw error;
  }
};
