import dynamic from 'next/dynamic';
import { fetchBots } from 'api/bot';
import { generateArray, highlightText } from 'utils';
import { useMutation } from 'react-query';
import { components } from 'react-select';
import React, { useState, useEffect } from 'react';
import { Bot, FetchBotsParams } from 'api/bot/types';
import reactSelectStylesConfig from 'lib/react-select';
import { getBotsFilterOptions } from 'utils/hardcoded-data';
import { BotCard } from 'components/lists/cards/BotCard';
import { BotSkeleton } from 'components/skeletons/BotSkeleton';
import Link from 'next/link';
import useWindowWidth from 'hooks/useWindowWidth';
import { MagnifyingGlass } from 'components/icons/MagnifyingGlass';
import { Close } from 'components/icons/Close';
import { useAuthStore } from 'store/auth';

const ReactSelect = dynamic(() => import('react-select'), { ssr: false });

type Props = {
  isLoggedSSR?: boolean;
};

export const Bots = ({ isLoggedSSR }: Props) => {
  const width = useWindowWidth();
  const { mutate, isLoading } = useMutation(fetchBots);
  const [bots, setBots] = useState<Bot[]>([]);
  const [maxPage, setMaxPage] = useState<number | null>(null);
  const { isLogged } = useAuthStore();
  const [params, setParams] = useState<FetchBotsParams>({
    mine: false,
    page: 0,
    size: 48,
    sort: 'featured',
    filter: ''
  });

  const isMaxExceeded = typeof maxPage === 'number' && bots.length >= maxPage;

  useEffect(() => {
    mutate(params, {
      onSuccess: ({ data, count }) => {
        setBots(data);
        setMaxPage(count);
      }
    });
  }, []);

  const handleParamsChange = (val: any, key: keyof FetchBotsParams) => {
    setParams((prev) => {
      const newParams = { ...prev, [key]: val, page: 0 };
      mutate(newParams, { onSuccess: (res) => setBots(res.data) });
      return newParams;
    });
  };

  return (
    <div className="flex flex-col pb-10">
      <div className="flex flex-col gap-4 text-center items-center">
        {!isLoggedSSR && (
          <>
            {!isLogged && (
              <>
                <div className="pt-6">
                  <h1 className="title">The Best AI Girlfriend and Chatbot</h1>
                  <p className="subtitle py-2">
                    Engaging backstories, impeccable memory, incredible experience.
                    <br /> Whether you&apos;re here for a flirt, fling, or roleplay, Crush&apos;s AI depth and
                    personality will blow you away.
                    <br /> Find your perfect companion today.
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className="flex gap-3 items-center justify-between mt-4">
        <ReactSelect
          isClearable
          instanceId={1}
          classNames={{ valueContainer: () => 'sm' }}
          className="w-auto min-w-[180px] sm:flex-none z-40 mb-4"
          placeholder={width > 768 ? 'Who are you into?' : 'Explore'}
          styles={reactSelectStylesConfig}
          components={{
            Option: ({ children, ...props }) => (
              <components.Option {...props}>
                {highlightText(props.selectProps.inputValue, children as string)}
              </components.Option>
            ),
            ValueContainer: ({ children, ...props }) => (
              <div className="flex items-center gap-2">
                <MagnifyingGlass className="text-primary size-5" />
                <components.ValueContainer {...props}>{children}</components.ValueContainer>
              </div>
            ),
            ClearIndicator: (props) => (
              <components.ClearIndicator {...props} className="!p-0">
                <Close className="size-4 mx-1 text-primary cursor-pointer" />
              </components.ClearIndicator>
            )
          }}
          options={getBotsFilterOptions()}
          onChange={(val: any) => handleParamsChange(val?.value || '', 'filter')}
        />

        <ReactSelect
          instanceId={2}
          className="min-w-[120px] sm:flex-none mb-4 z-40"
          isSearchable={false}
          classNames={{ valueContainer: () => 'sm' }}
          placeholder="Sort by"
          styles={reactSelectStylesConfig}
          components={{
            SingleValue: ({ children, ...props }) => (
              <components.SingleValue {...props}>
                {props.selectProps.menuIsOpen ? 'Sort by' : children}{' '}
              </components.SingleValue>
            )
          }}
          defaultValue={{ label: 'Featured', value: 'featured' }}
          onChange={(val: any) => handleParamsChange(val.value, 'sort')}
          options={[
            { label: 'Featured', value: 'featured' },
            { label: 'Popular', value: 'popular' },
            { label: 'New', value: 'new' }
          ]}
        />
      </div>

      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
        {bots.length
          ? bots.map((bot) => <BotCard key={bot.id} data={bot} />)
          : generateArray(8).map((i) => <BotSkeleton key={i} />)}
      </div>

      <div className="flex flex-col items-center gap-2 mt-6">
        <button
          className="btn btn-primary hover:text-brand hover:border-brand"
          disabled={isLoading || isMaxExceeded}
          onClick={() => {
            setParams((p) => {
              const newParams = { ...p, page: (p.page || 0) + 1 };
              mutate(newParams, { onSuccess: ({ data }) => setBots((p) => [...p, ...data]) });
              return newParams;
            });
          }}
        >
          {isMaxExceeded ? 'No more models available' : 'Load more'}
        </button>

        <Link href="/create" className="hover:text-brand gap-2" prefetch={false}>
          or Create your own!
        </Link>
      </div>
    </div>
  );
};
