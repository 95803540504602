import dayjs from 'utils/dayjs.config';
import { Bot } from 'api/bot/types';
import Link from 'next/link';

type Props = {
  data: Bot;
};

export const BotCard = ({ data }: Props) => (
  <Link
    prefetch={false}
    className="cy-bot-card relative border-2 border-primary rounded-xl overflow-hidden cursor-pointer trans hover:border-brand lg:hover:scale-105"
    href={`/chat/${data.id}/${data.bot_name}`}
    title={data.name + ' - Free AI Girlfriend and NSFW Chatbot at Crush.to'}
  >
    <div className="relative w-full h-[250px] lg:h-[400px] object-cover object-top z-10">
      <img
        src={data.custom_attributes.avatar_url}
        srcSet={`${data.custom_attributes.profile_image_url} 2x`}
        alt={data.name + ' - Free AI Girlfriend and NSFW Chatbot at Crush.to'}
        className="w-full h-full object-cover object-top"
      />
    </div>

    <div className="absolute bottom-0 z-20 bg-gradient-to-t from-[#1f1f1f] to-transparent h-28 w-full" />
    <div className="absolute bottom-0 z-30 flex flex-col md:gap-1 p-3 w-full">
      <div className="flex max-lg:flex-col items-start gap-1 lg:items-center justify-between w-full">
        <h2 className="text-xl font-medium">{data.name}</h2>
        <span className="text-stone-300 text-xs border border-white border-opacity-70 bg-black bg-opacity-50 rounded-md py-1 px-2  hidden md:block">
          {dayjs().diff(dayjs(data.custom_attributes.date_of_birth), 'year')} years
        </span>
      </div>
      <p className="subtitle-ellipsis text-sm text-stone-400">
        {data.custom_attributes.subtitle || data.custom_attributes.description}
      </p>
    </div>
  </Link>
);
